<template>
  <div>
    <infocard-base-form v-model="infocard" />
    <b-row>
      <b-col cols="7"> </b-col>
      <b-col cols="5">
        <b-form-group :label="T('Web.Generic.Infocards.InternalGroupId')">
          <b-form-input v-model="infocard.internalGroupId" />
        </b-form-group>
        <b-form-group :label="T('Web.Generic.Infocards.SupplierIdRefNumber')">
          <b-form-input v-model="infocard.supplierIdRefNumber" />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InfocardBaseForm from "./InfocardBaseForm.vue";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckbox,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    InfocardBaseForm,
    BFormTextarea,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormRadioGroup,
  },
  props: {
    infocard: {
      required: true,
    },
  },
  mounted() {
    this.$emit("submittable-status-changed", this.submittable);
  },
  computed: {
    submittable() {
      return (
        this.valueIsNotEmptyOrUnknown(this.infocard.name) &&
        this.valueIsNotEmptyOrUnknown(this.infocard.locationId)
      );
    },
  },
  watch: {
    submittable(newValue) {
      this.$emit("submittable-status-changed", newValue);
    },
  },
};
</script>